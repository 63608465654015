<template>
  <div>
    <el-dialog title="Attention" :visible.sync="dialogVisible" width="30%">
      <span
        ><app-i18n
          code="entities.exam.testListPage.downloadAppWarning.warningText"
        ></app-i18n
      ></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false"
          ><app-i18n
            code="entities.exam.testListPage.downloadAppWarning.cancel"
          ></app-i18n
        ></el-button>
        <el-button type="primary" @click="confirm"
          ><app-i18n
            code="entities.exam.testListPage.downloadAppWarning.confirm"
          ></app-i18n
        ></el-button>
      </span>
    </el-dialog>
    <el-dialog title="Attention" :visible.sync="dialog2" width="30%">
      <span
        ><app-i18n code="entities.exam.testListPage.useAppWarning"></app-i18n
      ></span>
    </el-dialog>
    <el-row class="cards">
      <!-- eslint-disable-next-line prettier/prettier -->
      <el-col
        :lg="6"
        :md="8"
        :sm="12"
        :xs="24"
        v-for="card in cards"
        :key="card.id"
      >
        <div class="card card-pricing">
          <div class="card-body" :class="card.background">
            <i class="card-icon" :class="card.cardLogo"></i>
            <h4 class="card-title">{{ card.name }}</h4>
            <h4>
              {{ card.examUpperLimit | formatExamFilter }}
            </h4>
            <div class="card-detail">
              <p>
                <i class="el-icon-time"></i>
                <!-- eslint-disable-next-line prettier/prettier -->
                <span class="card-time">{{
                  card.duration | formatExamDurationFilter
                }}</span>
              </p>
              <p>
                {{ card.qCount }}
                <app-i18n code="entities.onlineExam.question" />
                <span v-if="showPrice(card)">
                  {{ ' - ' + card.price + ' TL' }}
                </span>
              </p>
            </div>
            <a
              @click="click"
              class="el-button card-button el-button--default el-button--medium is-round"
              :href="card.nextPath || '#'"
            >
              <!-- eslint-disable-next-line prettier/prettier -->
              {{ card.btnText }}
            </a>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { routerAsync } from '@/app-module';
import { i18n } from '@/i18n';

function isExamReadyToStart(lowerLimitDate, upperLimitDate, serverMoment) {
  //Bu metot testi backend tarafinda var, degisiklik yapacaksan orda yap bunu degistir.
  const overLowerLimit = !lowerLimitDate
    ? true
    : moment(moment(lowerLimitDate)).isBefore(
        serverMoment,
        'minutes',
      );
  const underUpperLimit = !upperLimitDate
    ? true
    : moment(moment(upperLimitDate)).isAfter(
        serverMoment,
        'minutes',
      );
      
  return overLowerLimit && underUpperLimit;
}
function setCardView(row, serverMoment) {
  if (row.isFree === false && row.registered === false) {
    row.btnText = i18n('entities.exam.testListPage.btnUnlockTest');
    row.cardLogo = 'el-icon-bank-card';
    row.background = 'bg-locked';
    row.nextPath = '/unlock/' + row.id;
  } else if (row.hasStarted === true) {
    row.btnText = i18n('entities.exam.testListPage.btnContinueTest');
    row.cardLogo = 'el-icon-video-play';
    row.background = 'bg-continue';
    row.nextPath = '/checkbrowser/' + row.id + '/' + row.examLanguage.code;
  } else if (
    isExamReadyToStart(row.examLowerLimit, row.examUpperLimit, serverMoment) === true
  ) {
    row.btnText = i18n('entities.exam.testListPage.btnStartTest');
    row.cardLogo = 'el-icon-view';
    row.background = 'bg-ready';
    row.nextPath = '/checkbrowser/' + row.id + '/' + row.examLanguage.code;
  } else {
    row.btnText = i18n('entities.exam.testListPage.btnLoadingTest');
    row.cardLogo = 'el-icon-sunrise-1';
    row.background = 'bg-loading';
  }
  return row;
}

let selectedHref = '';

export default {
  name: 'test-list-page',
  data() {
    return {
      dialogVisible: false,
      dialog2: false
    };
  },
  computed: {
    ...mapGetters({
      rows: 'exam/list/rows',
      serverMoment: 'exam/list/servermoment'
    }),
    cards() {
      this.rows.forEach(r => {
        setCardView(r, this.serverMoment);
      });
      return this.rows;
    }
  },
  async mounted() {
    await this.doFetchTests();
  },
  methods: {
    ...mapActions({
      doFetchTests: 'exam/list/doFetchTests'
    }),
    click(evt) {
      evt.preventDefault();
      selectedHref = evt.target.getAttribute('href');
      //this._activateFullscreen();
      routerAsync().push(selectedHref);
    },
    confirm() {
      this.dialogVisible = false;
      window.deferredPrompt.prompt();
      window.deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          routerAsync().push(selectedHref);
          window.deferredPrompt = null;
        }
      });
    },
    _activateFullscreen() {
      let fullscreenElement = document.documentElement;
      if (fullscreenElement.requestFullscreen) {
        fullscreenElement.requestFullscreen();
      } else if (fullscreenElement.mozRequestFullScreen) {
        /* Firefox */
        fullscreenElement.mozRequestFullScreen();
      } else if (fullscreenElement.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        fullscreenElement.webkitRequestFullscreen();
      } else if (fullscreenElement.msRequestFullscreen) {
        /* IE/Edge */
        fullscreenElement.msRequestFullscreen();
      }
    },
    showPrice(card) {
      return card.isFree === false && card.registered === false;
    }
  }
};
</script>

<style scoped>
.cards {
  padding: 0 1rem;
  max-width: 1280px;
  margin: 0 auto;
}

.cards > .el-col {
  padding: 0 12px;
}

.card {
  color: white;
  text-align: center;
  border: 0;
  margin-top: 30px;
  width: 100%;

  border-radius: 6px;
}

h4 {
  margin: 0.5rem 0 0 0;
  font-family: 'Roboto Slab', 'Times New Roman', serif;
}
.card-title {
  font-size: 1.5rem;
  line-height: 1.5em;
}

.card-detail {
  margin-top: 2.5rem;
}

.card-detail > p {
  margin: 0.25rem 0;
  font-size: 14px;
}

.card-body {
  border-radius: 6px;
  padding: 15px;
  color: white;
  margin: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.card-icon {
  font-size: 3.5rem;
  padding: 1.5rem 0 1rem 0;
}

.card-button {
  margin: 2.5rem 0 0.25rem 0;
  color: #999999;
  font-size: 0.75rem;
  font-weight: 500;
}

.card-time {
  margin-left: 0.5rem;
  color: rgba(255, 255, 255, 0.8);
}

.bg-loading {
  background-color: #343a40;
}
.bg-continue {
  background: linear-gradient(60deg, #ffa726, #f57c00);
}
.bg-ready {
  background: linear-gradient(60deg, #66bb6a, #388e3c);
}
.bg-locked {
  /* background: linear-gradient(60deg, #da3553, #8f1a2f); */
  background: linear-gradient(60deg, #ab47bc, #7b1fa2);
}
</style>
